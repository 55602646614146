<template>
  <div class="app-container app-container-material">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="素材类别" style="margin:0 20px 0 50px">
        <el-select v-model="form.category" placeholder="请选择素材类别">
          <el-option label="全部" value=""></el-option>
          <el-option label="上传素材" value="1"></el-option>
          <el-option label="发帖截图" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件名" style="margin:0 20px 0 50px">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="关键词" style="margin:0 20px 0 50px">
        <el-input v-model="form.search"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchFun">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="danger"
          :disabled="selectData.length == 0"
          @click="delFun"
        >
          批量删除
        </el-button>
      </el-form-item>
    </el-form>
    <div style="color:red">
      素材可手动删除,默认保存60天,已收藏素材永不过期
    </div>
    <div style="margin:5px 0; overflow:hidden;">
      <div style="float:left;">使用容量:</div>
      <div style="float:left;" class="prossBox">
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="usedPercentage"
        ></el-progress>
      </div>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      class="tableList3"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="70" />
      <el-table-column prop="name" label="文件名"></el-table-column>
      <el-table-column prop="category" label="来源">
        <template slot-scope="scope">
          <el-tag size="" v-if="scope.row.category == 1">
            上传素材
          </el-tag>
          <el-tag type="warning" v-if="scope.row.category == 2">
            发帖截图
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="mime_type" label="文件类型"></el-table-column>
      <el-table-column prop="size" label="文件大小">
        <template slot-scope="scope">
          {{ formatSizeUnits(scope.row.size) }}
        </template>
      </el-table-column>
      <el-table-column prop="e_tag" label="md5"></el-table-column>

      <!-- <el-table-column prop="description" label="简介"></el-table-column> -->

      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="getStatusTip(scope.row.state, statusMap)"
            placement="top"
          >
            <el-tag
              :type="getStatusType(scope.row.state, statusMap)"
              disable-transitions
            >
              {{ getStatusLabel(scope.row.state, statusMap) }}
            </el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="预览图">
        <template slot-scope="scope">
          <img :src="scope.row.thumbnail_url" style="height:90px" />
          <el-button
            slot="reference"
            size="mini"
            type="text"
            @click="showOnlien(scope.row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注">
        <template slot-scope="scope">
          {{ scope.row.remark }}
          <el-button
            slot="reference"
            size="mini"
            type="text"
            @click="tryEdit(scope.row)"
          >
            修改备注
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="create_time" label="时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            title="确定删除该素材吗？"
            @confirm="deleteMasterialHandle(scope.row.id)"
          >
            <el-button
              slot="reference"
              size="mini"
              type="text"
              style="color:red"
            >
              删除素材
            </el-button>
          </el-popconfirm>

          <el-button
            v-if="scope.row.state !== 2"
            slot="reference"
            size="mini"
            type="text"
            @click="updateMaterialInfo(scope.row.id, { state: 2 })"
          >
            永久收藏
          </el-button>
          <el-button
            v-if="scope.row.state === 2"
            slot="reference"
            size="mini"
            type="text"
            @click="updateMaterialInfo(scope.row.id, { state: 1 })"
          >
            取消收藏
          </el-button>
          <el-button
            slot="reference"
            size="mini"
            type="text"
            @click="downMaterial(scope.row.id)"
          >
            下载素材
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="form.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="form.total"
    ></el-pagination>

    <el-drawer
      title="在线查看"
      :visible.sync="drawer"
      :with-header="false"
      size="50%"
      direction="ltr"
    >
      <img
        class="m-drawer-img"
        v-if="
          drawer &&
            showMaterial.mime_type &&
            showMaterial.mime_type.startsWith('image')
        "
        :src="showUrl"
      />
      <video-player
        v-if="
          drawer &&
            showMaterial.mime_type &&
            showMaterial.mime_type.startsWith('video')
        "
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </el-drawer>
    <el-dialog :visible.sync="editForm.show" width="400px">
      <el-form ref="form" :model="form">
        <el-form-item label="备注">
          <el-input v-model="editForm.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialList,
  deleteMaterial,
  getMaterial,
  updateMaterial,
  delArrApi
} from '@/api/material'
import { getStorageInfo } from '@/api/user'

import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'

// 引入复制插件
import Clipboard from 'clipboard'

import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  components: { videoPlayer },
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/t8.png') + '"',
      selectData: [],
      accountId: 0,
      list: null,
      listLoading: true,
      usedPercentage: 0,
      drawer: false,
      showMaterial: {},
      showUrl: '',
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: '' // url地址
          }
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      form: {
        category: null,
        name: '',
        remark: '',
        pageIndex: 1,
        pageSize: 5,
        total: 0,
        videoStyle: 'video/snapshot,t_0,f_jpg,h_90,m_fast',
        imageStyle: 'image/resize,m_fixed,h_90'
      },
      editForm: {
        show: false,
        id: '',
        remark: ''
      },
      count: 0,
      statusMap: {
        2: {
          type: 'success',
          label: '收藏',
          tip: '已收藏的文件永不过期'
        },
        1: {
          type: 'success',
          label: '有效',
          tip: '文件在有效期内，超过有效期将被删除'
        },
        '-1': {
          type: 'danger',
          label: '逻辑删除',
          tip: '该文件用户已操作删除,但OSS中仍留有备份'
        },
        '-2': {
          type: 'danger',
          label: '物理删除',
          tip: '该文件已经从OSS中删除'
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    // hanleTimeDelay,
    getStatusType,
    getStatusLabel,
    getStatusTip,
    updateMaterial,
    searchFun() {
      this.selectData = []
      this.form.pageIndex = 1
      this.fetchData()
    },

    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await getMaterialList(this.form)
      this.form.total = count
      this.list = items
      this.listLoading = false
      const { used, own } = await getStorageInfo()

      this.usedPercentage = Math.floor((used / own) * 100)
      if (this.usedPercentage > 100) {
        this.usedPercentage = 100
      }
    },
    handleSizeChange(pageSize) {
      this.selectData = []
      this.form.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.selectData = []
      this.form.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + ' GB'
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + ' MB'
      } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + ' KB'
      } else if (bytes > 1) {
        bytes = bytes + ' bytes'
      } else if (bytes === 1) {
        bytes = bytes + ' byte'
      } else {
        bytes = '0 byte'
      }
      return bytes
    },
    async deleteMasterialHandle(id) {
      await deleteMaterial(id)
      await this.fetchData()
    },
    // 点击复制
    copy(value) {
      const that = this
      that.copeValue = 'https://twitter.com/' + value
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message.success('复制成功！')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制!')
        // 释放内存
        clipboard.destroy()
      })
    },
    async getMaterialDownUrl(id) {
      const { signature_url: signature_url } = await getMaterial(id, {
        videoStyle: 'video/snapshot,t_0,f_jpg,h_400,m_fast',
        imageStyle: 'image/resize,m_fixed,h_400'
      })
      return signature_url
    },
    async downMaterial(id) {
      const url = await this.getMaterialDownUrl(id)
      window.location.href = url
    },
    async updateMaterialInfo(id, data) {
      await updateMaterial(id, data)
      await this.fetchData()
    },
    async showOnlien(material) {
      this.showMaterial = material
      const url = await this.getMaterialDownUrl(material.id)
      this.showUrl = url
      this.playerOptions.sources[0].src = url
      // this.playerOptions.sources[0].type = material.mimeType
      // this.playerOptions.poster = material.thumbnailUrl

      this.drawer = true
    },
    async tryEdit(material) {
      this.editForm.id = material.id
      this.editForm.remark = material.remark
      this.editForm.show = true
    },
    async onSubmit() {
      await updateMaterial(this.editForm.id, { remark: this.editForm.remark })
      this.fetchData()
      this.editForm.show = false
    },
    handleSelectionChange(data) {
      this.selectData = data.map(item => {
        return item.id
      })
    },
    delFun() {
      this.$confirm('此操作将删除选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delArrApi({
            ids: this.selectData
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.selectData = []
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.app-container-material {
  position: relative;
}
.prossBox {
  width: 90% !important;
  margin-left: 20px;

  .el-progress-bar__outer {
    background: #e2e4e5;
    border: 1px solid #d7d7d7;
    .el-progress-bar__inner {
      background: #6fb064;
    }
  }
}
.m-drawer-img {
  width: 100%;
  height: auto;
}
</style>
